<template>
  <div class="app-contianer">
    <PageBg />

    <div id="app-content">
      <!--    用户信息和设置-->
      <div class="user-back ">
        <div class="user-avatar">
          <img :src="custMsg.imgUrl" v-if="custMsg.imgUrl && custMsg.imgUrl !== ''" />
          <img :src="defaultUserImg" v-else />
        </div>
        <div class="user-text">
          <div class="user-title f38">
            <strong class="vertical-middle">{{ custMsg.nickName || '用户'+ custMsg.custId }} </strong>
            <span class="page-icon2 vip margin-lr2 vertical-middle" v-if="custMsg.vipPlus === 1"></span>
            <span class="page-icon2 noVip margin-lr2 vertical-middle" v-if="custMsg.vipPlus === 0"></span>
          </div>
          <div class="margin-t1 f34">ID：{{ custMsg.custId }}</div>
        </div>
        <div class="user-settings">
          <van-icon name="setting-o" size="20px" color="#222" @click="goPage('/settings')" />
        </div>
      </div>

      <div class="balance-box page-item">
        <div class="vip-box page-item" @click="pluseVip()">
          <div><span class="page-icon2"></span></div>
          <div class="flex-1">
            <p><span>开通会员</span></p>
            <p class="grey f32 ">每单平均多返3元，每月多省500元</p>
          </div>
          <div class="mainBut">立即开通</div>
        </div>

        <div class="total-info page-item">
          <div>
            <strong class="f48">{{tfNot || 0}}</strong>
            <div class="f34 margin-t2">待入账</div>
          </div>
          <div>
            <strong class="f48">{{tfMoney || 0}}</strong>
            <div class="f34 margin-t2">账户余额</div>
          </div>
          <div>
            <strong class="f48">{{totalInfo.tfProfit || 0}}</strong>
            <div class="f34 margin-t2">累计节省</div>
          </div>
        </div>
      </div>

      <div class="menu-box page-item order">
        <div class="title f38">
          <strong>外卖订单</strong>
          <div class="fr f34 grey" @click="goOrderPage('all')">全部订单<i class="el-icon-arrow-right"></i></div>
        </div>

        <div class="but-box">
          <div class="item" @click="goOrderPage('audit')">
            <div class="page-icon2 audit"></div>
            <div class="f34">待处理</div>
          </div>
          <div v-if="!isiOSApp" class="item" @click="goOrderPage('pass')">
            <div class="page-icon2 pass"></div>
            <div class="f34">已完成</div>
          </div>
          <div v-if="!customZhang" class="item" @click="goOrderPage('reject')">
            <div class="page-icon2 reject"></div>
            <div class="f34">已驳回</div>
          </div>
          <div class="item">
            <div class="page-icon2 service" @click="showService()"></div>
            <div class="f34">联系售后</div>
          </div>
        </div>
      </div>

      <!--    成为vip-->
      <!-- <div class="page-item plusvip" @click="pluseVip()">
        <div class="vip">
          <div class="plusvip-button">开通</div>
        </div>
        <img src="../../assets/user/plusvip.png" alt="">
      </div> -->

      <!-- 菜单 -->
      <div class="menu-box page-item">
        <div class="title f38"><strong>我的功能</strong></div>

        <div class="but-box">
          <div class="item" @click="goPage('/withdrawal')">
            <div class="page-icon2 balance"></div>
            <div class="f34">账号余额</div>
          </div>
          <div v-if="!isiOSApp" class="item" @click="goPage('/ourteam')">
            <div class="page-icon2 team"></div>
            <div class="f34">我的团队</div>
          </div>
          <!-- <div v-if="!customZhang" class="item" @click="goShopUrl()">
            <div class="page-icon2 shop"></div>
            <div class="f34">商家入驻</div>
          </div> -->
          <div v-if="custMsg.promoter === '1'" class="item" @click="goPage('/promoter')">
            <div class="page-icon2 promote"></div>
            <div class="f34">推广列表</div>
          </div>
          <div class="item" @click="jionGroup()">
            <div class="page-icon2 group"></div>
            <div class="f34">加福利群</div>
          </div>
          <div class="item" @click="goPage('/detailed')">
            <div class="page-icon2 course"></div>
            <div class="f34">新手教程</div>
          </div>
          <div class="item" @click="goPage('/contactus')">
            <div class="page-icon2 contact"></div>
            <div class="f34">联系我们</div>
          </div>
          <div class="item" @click="goPage('/settings')">
            <div class="page-icon2 setting"></div>
            <div class="f34">个人设置</div>
          </div>
          <div class="item" @click="goPage('/cooperation')">
            <div class="page-icon2 cooperation"></div>
            <div class="f34">我要合作</div>
          </div>
        </div>
      </div>

      <!-- 皮肤切换 -->
      <div class="page-item themes-box" v-if="agentId === 'A1817967050'">
        <div class="title f38"><strong>演示皮肤切换</strong></div>
        <div class="content">
          <div class="item" v-for="item in themesMap" :key="item.value" @click="changeThemes(item)">
            <!-- <div class="color mainBgColor"></div> -->
            <div class="color" :style="{backgroundColor:item.color}"></div>
          </div>
        </div>
      </div>

      <!-- 菜单end -->
      <div class="footer" v-if="agentId === 'A10014409227'">佛山稀饭科技提供技术支持</div>

      <van-popup v-model="showGroupImg">
        <img :src="groupUrl">
      </van-popup>

      <van-popup v-model="showPopup">
        <img :src="popupUrl">
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getlist, getToken } from '../../api'
import { changeTitle } from '@/utils/utils'
import PageBg from '@/components/PageBg.vue'
import thechangePhone from '@/components/changePhone.js'
const agentCustomize = require('@/utils/agentCustomize')

export default {
  name: 'user',
  components: { PageBg },
  data() {
    return {
      defaultUserImg: agentCustomize.defaultUserImg,
      tfMoney: '',
      tfNot: '',
      totalInfo: {},
      showGroupImg: false,
      showPopup: false,
      groupUrl: '',
      popupUrl: '',
      isiOSApp: false,
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'versions', 'agentConfig', 'customZhang', 'isApp', 'agentId', 'themesMap']),
  },
  created() {
    // 未绑定手机号
    if (this.custMsg.phoneUser === "N") {
      thechangePhone()
    }
    changeTitle(this.agentConfig.bwcName)
    this.getYe()
    this.getGroupUrl()
    this.getTotalInfo()

    let ran = navigator.userAgent
    this.isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
    this.isiOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    this.isiOSApp = this.isiOS && this.isApp
  },
  methods: {
    // 获取统计数据
    getTotalInfo() {
      getlist({
        trxCode: 'MS901',
      }).then((res) => {
        if (res.trxCode) {
          this.totalInfo = res
        }
      })
    },
    //用户余额
    getYe() {
      getToken({ trxCode: 'TC902', requestType: 'applet' }).then((res) => {
        if (res.accArray && res.accArray.length > 0) {
          const accarray = res.accArray.find((acc) => acc.accCode === 'PROFIT')
          const accarraynot = res.accArray.find(
            (acc) => acc.accCode === 'PROFIT_FREEZE'
          )
          this.tfMoney = accarray.balance
          this.tfNot = accarraynot.balance
        }
      })
    },

    changeThemes(item) {
      const body = document.body
      body.className = item.value
    },

    // 获取客服信息--- 福利群二维码
    getGroupUrl() {
      getToken({
        trxCode: 'XF906',
      }).then((res) => {
        this.groupUrl = res.wechatImgLink
      })
    },

    // 加福利群
    jionGroup() {
      if (!this.groupUrl) {
        this.$toast('平台未设置')
        return
      }
      this.showGroupImg = true
    },

    // 前往商家版
    goShopUrl() {
      var url = `${this.agentConfig.SELF_DOMAIN_SHOP}/shop?agentId=${localStorage.getItem('agent')}`
      window.location.href = url
    },

    // 购买会员
    pluseVip() {
      if (this.isiOSApp) {
        this.showService()
        return
      }
      this.goPage('/plusevip')
    },

    // 页面跳转
    goPage(path) {
      this.$router.push({ path: path })
    },

    goOrderPage(val) {
      this.$router.push({ path: '/orderList', query: { tabType: val } })
    },

    showService() {
      if (!this.agentConfig.SERVICE_WECHATCODE) {
        this.$toast('平台未设置')
        return
      }

      this.popupUrl = this.agentConfig.SERVICE_WECHATCODE
      this.showPopup = true
    },
  },
}
</script>

<style scoped lang="less">
.user-back {
  display: flex;
  align-items: center;
  margin: 4vw;

  .user-avatar {
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
    }
  }

  .user-text {
    flex: 1;
    margin: 0 3vw;
  }

  .page-icon2.vip {
    width: 20px;
    height: 20px;
    background-position: -108px -155px;
  }
  .page-icon2.noVip {
    width: 20px;
    height: 20px;
    background-position: -82px -155px;
  }
}

.menu-box.order {
  .audit {
    background-position: -330px -223px;
  }
  .pass {
    background-position: -365px -223px;
  }
  .reject {
    background-position: -398px -223px;
  }
  .service {
    background-position: -325px -319px;
  }
}

.menu-box {
  .but-box {
    display: flex;
    text-align: center;
    flex-wrap: wrap;

    .item {
      width: 25%;
      margin-top: 18px;
      margin-bottom: 8px;
    }
  }
  .page-icon2 {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    margin-bottom: 8px;
  }
  .balance {
    background-position: -435px -223px;
  }
  .team {
    background-position: -468px -223px;
  }
  .shop {
    background-position: -296px -253px;
  }
  .promote {
    background-position: -328px -253px;
  }
  .group {
    background-position: -296px -253px;
  }
  .course {
    background-position: -293px -319px;
  }
  .contact {
    background-position: -394px -253px;
  }
  .setting {
    background-position: -463px -253px;
  }
  .cooperation {
    background-position: -429px -253px;
  }
}

.balance-box {
  padding: 0;
}

.vip-box {
  margin: 0;
  display: flex;
  align-items: center;

  .but {
    display: inline-block;
    background: #fff;
    border-radius: 6px;
    padding: 1vw 3vw;
  }
  .page-icon2 {
    width: 29px;
    height: 25px;
    background-position: -166px -149px;
    margin-right: 3vw;
  }
}
.blueThemes .vip-box {
  background: linear-gradient(90deg, #fafbff 0%, #ecf0fc 100%);
}
.cyanThemes .vip-box {
  background: linear-gradient(90deg, #fcffff 0%, #effcfd 100%);
}
.defaultThemes .vip-box {
  background: linear-gradient(90deg, #fdfcff 0%, #f8f5ff 100%);
}
.orangeThemes .vip-box {
  background: linear-gradient(90deg, #fffdfb 0%, #fff6f2 100%);
}
.redThemes .vip-box {
  background: linear-gradient(90deg, #fffcfc 0%, #fff1f1 100%);
}

.total-info {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.plusvip {
  padding: 0;
  position: relative;
  border-radius: 3vw;

  .vip {
    position: absolute;
    top: 12px;
    right: 24px;
    .plusvip-button {
      padding: 1.8vw 6vw;
      background: linear-gradient(-45deg, #f7c28c 0%, #ffe7ce 100%);
      border-radius: 5vw;
      font-size: 3.5vw;
      font-weight: bold;
      color: #212438;
    }
  }

  > img {
    width: 100%;
  }
}

.themes-box {
  .content {
    display: flex;
    justify-content: space-around;
    margin: 12px 0;
  }
  .item {
    text-align: center;
  }
  .item .color {
    width: 10vw;
    height: 4vw;
    border-radius: 4px;
  }
}

.footer {
  color: #bbb;
  font-size: 3vw;
  text-align: center;
  margin: 2vw;
}
</style>
